import React, { useEffect, useState, useRef } from "react";
import "../App.css";
import { loadImagesData, rateImage, getUserBatch } from "../api";
import axios from "axios";
import { loadImages } from "./imagesPreloader";
import timerImg from "../assets/timer.png";
let reservelastIdToPre = 0;
let intervalId;
let lastIdWhenClickedOnPrevious = 0;
let source;
let imageBaseUrl = "https://s3.eu-central-1.wasabisys.com/instaua/images/";
let batchLimit = 10;
export const InstagramViewImages = ({
  useData,
  batchStartingId,
  setBatchStartingId,
  batchArr,
  setBatchArr,
}) => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [pBtnActive, setPBtnActive] = useState(false);
  const [rBtnActive, setRBtnActive] = useState(false);
  const [timer, setTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instagramData, setInstagramData] = useState([]);
  const [showingRecord, setShowingRecord] = useState(1);
  const [isPreReqRecived, setIsPreReqRecived] = useState(false);
  const [isTimerDisable, setisTimerDisable] = useState(true);
  const pauseRef = useRef(null);
  const userData = useRef(null);
  const disableTimerRef = useRef(true);
  // Next
  let stopNext = instagramData?.id >= useData.count;
  useEffect(() => {
    reservelastIdToPre = useData.last_id;
  }, []);

  const getData = async () => {
    setIsPreReqRecived(false);
    if (stopNext) {
      clearInterval(intervalId);
      setTimer(false);
    } else {
      source = axios.CancelToken.source();
      let lastId = userData.current.id + 1;
      let response = await loadImagesData(
        reservelastIdToPre,
        // useData.id,
        lastId,
        useData.last_id == reservelastIdToPre,
        source,
        useData.where_filter
      );
      if (response) {
        if (reservelastIdToPre >= useData.count) {
          clearInterval(intervalId);
          setTimer(false);
        }
        //
        if (!pauseRef.current && !(reservelastIdToPre >= useData.count)) {
          if (useData.last_id == reservelastIdToPre) {
            useData.last_id += 1;
          }
          reservelastIdToPre += 1;

          setInstagramData(response);
          userData.current = response;
          setRBtnActive(false);
          if (reservelastIdToPre > lastIdWhenClickedOnPrevious) {
            setShowingRecord((pre) => (pre += 1));
          }
        }
        if (!pauseRef.current && reservelastIdToPre >= useData.count) {
          setInstagramData(response);
          userData.current = response;
          setRBtnActive(false);
        }
      }
      setIsPreReqRecived(true);
    }
  };
  useEffect(() => {
    if (!pBtnActive && timer && isPreReqRecived && !isTimerDisable) {
      intervalId = setTimeout(() => {
        getData();
      }, [parseInt(`${useData.change_time}000`)]);
    } else {
      clearInterval(intervalId);
    }
  }, [pBtnActive, timer, isPreReqRecived, isTimerDisable]);
  // Start click
  const handleOnClick = async () => {
    source = axios.CancelToken.source();
    lastIdWhenClickedOnPrevious = 0;
    setLoading(true);
    setIsFirstTime(false);

    const response = await loadImagesData(
      useData.last_id,
      useData.id,
      true,
      source,
      useData.where_filter
    );
    if (reservelastIdToPre != useData.count) {
      useData.last_id += 1;
      reservelastIdToPre += 1;
    }
    setIsPreReqRecived(true);
    setLoading(false);
    setInstagramData(response);
    userData.current = response;

    if (reservelastIdToPre >= useData.count) {
      clearInterval(intervalId);
      setTimer(false);
    } else {
      setTimer(true);
    }
  };

  useEffect(() => {
    const handleKeywordAction = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        handlePause();
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        handleClickOnR();
      }
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        getPreviousUser();
      }
      if (event.key === "ArrowRight") {
        event.preventDefault();
        if (disableTimerRef.current) {
          getNextUser();
        }
      }
      if (event.key === "t") {
        toggleTimmer();
      }
    };
    window.addEventListener("keydown", handleKeywordAction);
    return () => {
      window.removeEventListener("keydown", handleKeywordAction);
    };
  }, [pBtnActive, rBtnActive]);
  function handlePause() {
    setPBtnActive((pre) => {
      if (
        pre &&
        reservelastIdToPre != useData.count &&
        useData.last_id != reservelastIdToPre
      ) {
        if (useData.last_id == reservelastIdToPre) {
          useData.last_id += 1;
        }
        reservelastIdToPre += 1;
      }
      pauseRef.current = !pre;
      // only cancel when paused
      !pre && source.cancel("Request canceled");
      return !pre;
    });
    setTimer(true);
  }
  // Pre
  const getPreviousUser = async () => {
    source = axios.CancelToken.source();
    setTimer(false);
    lastIdWhenClickedOnPrevious = useData.last_id;
    if (
      useData.last_id == reservelastIdToPre &&
      reservelastIdToPre != useData.count
    ) {
      reservelastIdToPre -= 2;
    } else {
      reservelastIdToPre -= 1;
    }

    const response = await loadImagesData(
      userData.current.id - 1,
      useData.id,
      false,
      source,
      useData.where_filter,
      true
    );
    await loadImages([{ user_id: response.accountId }]);
    setInstagramData(response);
    userData.current = response;
    setTimer(true);
    setRBtnActive(false);
    setIsPreReqRecived(true);
  };

  async function getNextUser() {
    source = axios.CancelToken.source();
    debugger;
    let lastId = userData.current.id + 1;
    let updateLastId = false;
    if (useData.last_id <= lastId) {
      useData.last_id = lastId;
      updateLastId = true;
    }
    reservelastIdToPre = lastId;
    const response = await loadImagesData(
      lastId,
      useData.id,
      updateLastId,
      source,
      useData.where_filter
    );
    if (reservelastIdToPre > lastIdWhenClickedOnPrevious) {
      setShowingRecord((pre) => (pre += 1));
    }
    setInstagramData(response);
    userData.current = response;
    debugger;
    setRBtnActive(false);
  }

  const getProfilesShowed = () => {
    const value = (useData.last_id / useData.count) * 100;
    return parseInt(value);
  };
  const handleClickOnR = async () => {
    setRBtnActive(true);
    await rateImage(
      userData.current?.username,
      userData.current?.accountId,
      useData.id,
      useData.input_save
    );
  };

  // load next when previous batch upto 4....
  useEffect(() => {
    console.log(batchArr[3]?.username == userData.current?.username);
    if (batchArr[3]?.username == userData.current?.username) {
      getUserBatch(useData.last_id, batchLimit, useData.where_filter)
        .then((res) => {
          setBatchStartingId(useData.last_id);
          loadImages(res);
          setBatchArr(res);
        })
        .catch((err) => {
          alert("Something went wrong with downloading batch images");
        });
    }
  }, [instagramData]);

  function toggleTimmer() {
    setisTimerDisable((pre) => {
      disableTimerRef.current = !pre;
      return !pre;
    });
  }

  return (
    <>
      {isFirstTime ? (
        <div className="header">
          <button onClick={handleOnClick} type="button" className="btn-primary">
            Start
          </button>
        </div>
      ) : loading ? (
        <div className="header">loading....</div>
      ) : (
        <div className="parent-wrapper">
          <div className="menu-main">
            <div className="menu1">
              <button
                disabled={instagramData?.id <= 1 ? true : false}
                onClick={getPreviousUser}
                className={`menu-btn ${
                  instagramData?.id <= 1 ? "cur-disable" : ""
                }`}
              >
                {"<"}
              </button>
              {isTimerDisable ? (
                <button
                  onClick={getNextUser}
                  disabled={stopNext}
                  className={`menu-btn ${stopNext ? "cur-disable" : ""}`}
                >
                  {">"}
                </button>
              ) : (
                <button
                  onClick={handlePause}
                  className={`menu-btn ${pBtnActive && "btn-active"}`}
                >
                  P
                </button>
              )}
            </div>
            <div className="menu2">
              <div className="menu2Btn-wrapper">
                <button
                  onClick={toggleTimmer}
                  className={`menu-btn ${isTimerDisable && "btn-active"}`}
                >
                  <img className="timerImg" src={timerImg} alt="" />
                </button>
                <button
                  onClick={handleClickOnR}
                  className={`menu-btn ${rBtnActive && "btn-active"}`}
                >
                  R
                </button>
              </div>

              <div>Profiles showed: {showingRecord}</div>
              <div>%: {getProfilesShowed()}</div>
              <div>ID: {instagramData?.accountId}</div>
              <div>DB: {instagramData?.id}</div>
              <div>Total done: {instagramData?.totalDone}</div>
            </div>
          </div>

          <div className="img-section">
            <div className="row-header">
              <div className="row">
                {new Array(12).fill().map((image, index) => {
                  let accountId = instagramData.accountId;
                  let imageUrl;
                  if (accountId) {
                    imageUrl = imageBaseUrl + accountId + "_" + index + ".jpeg";
                  }
                  return (
                    <div
                      key={index}
                      className={`img-block ${"image-" + (index + 1)}`}
                    >
                      <img
                        key={index}
                        src={imageUrl}
                        className="img-styled"
                        alt=""
                      />

                      {index + 1 === 12 && (
                        <div className="menu-main">
                          <div>
                            <div>Profiles showed: {showingRecord}</div>
                            <div>%: {getProfilesShowed()}</div>
                            <div>ID: {instagramData?.accountId}</div>
                            <div>DB: {instagramData?.id}</div>
                            <div>Total done: {instagramData?.totalDone}</div>
                          </div>
                          <div>
                            <div className="menu1">
                              <button
                                disabled={instagramData?.id <= 1 ? true : false}
                                onClick={getPreviousUser}
                                className={`menu-btn ${
                                  instagramData?.id <= 1 ? "cur-disable" : ""
                                }`}
                              >
                                {"<"}
                              </button>
                              {isTimerDisable ? (
                                <button
                                  onClick={getNextUser}
                                  disabled={stopNext}
                                  className={`menu-btn ${
                                    stopNext ? "cur-disable" : ""
                                  }`}
                                >
                                  {">"}
                                </button>
                              ) : (
                                <button
                                  onClick={handlePause}
                                  className={`menu-btn ${
                                    pBtnActive && "btn-active"
                                  }`}
                                >
                                  P
                                </button>
                              )}
                            </div>
                            <div className="menu2">
                              <div className="menu2Btn-wrapper">
                                <button
                                  onClick={toggleTimmer}
                                  className={`menu-btn ${
                                    isTimerDisable && "btn-active"
                                  }`}
                                >
                                  <img
                                    className="timerImg"
                                    src={timerImg}
                                    alt=""
                                  />
                                </button>
                                <button
                                  onClick={handleClickOnR}
                                  className={`menu-btn ${
                                    rBtnActive && "btn-active"
                                  }`}
                                >
                                  R
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
