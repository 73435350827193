let imageBaseUrl = "https://s3.eu-central-1.wasabisys.com/instaua/images/";
export async function loadImages(data) {
  try {
    let loadedImageCount = 0;
    const promises = [];
    data.forEach((element) => {
      let accountId = element.user_id;
      for (let index = 0; index < 12; index++) {
        let imageUrl = imageBaseUrl + accountId + "_" + index + ".jpeg";
        const img = new Image();
        img.src = imageUrl;
        const promise = new Promise((resolve, reject) => {
          img.onload = () => {
            loadedImageCount++;
            resolve();
          };
          img.onerror = () => {
            loadedImageCount++;
            resolve();
          };
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
  } catch (error) {
    console.error("Error preloading images:", error);
    throw error;
  }
}
