import React, { useState, useEffect } from "react";
import { InstagramViewImages } from "./components";
import { checkUserAvailble } from "./api";
import { ErrorBoundry } from "./components/ErrorBoundry";
import { getUserBatch } from "./api";
import { loadImages } from "./components/imagesPreloader";

function App() {
  const slug = window.location.pathname.replace("/", "");
  const [isUserAvailble, setIsUserAvailble] = useState(false);
  const [batchStartingId, setBatchStartingId] = useState("");
  const [isloading, setIsLoading] = useState("Loading...");
  const [batchArr, setBatchArr] = useState([]);
  console.log(batchArr);
  useEffect(() => {
    if (slug) {
      setIsLoading("Loading...");
      checkUserAvailble(slug)
        .then((res) => {
          setIsLoading("Downloading images...");
          setIsUserAvailble(res);
          setBatchStartingId(res.last_id);
          getUserBatch(res.last_id, 10, res.where_filter)
            .then(async (data) => {
              setBatchArr(data);
              await loadImages(data);
              setIsLoading(null);
            })
            .catch((err) => {
              setIsLoading(null);
            });
        })
        .catch((err) => {
          setIsLoading(null);
        });
    } else {
      setIsLoading(null);
    }
  }, [slug]);
  return isloading ? (
    <div className="header">{isloading}</div>
  ) : !slug ? (
    <>
      <ErrorBoundry isNotFound={true} />
    </>
  ) : !isUserAvailble ? (
    <ErrorBoundry isNotFound={false} />
  ) : (
    <>
      <InstagramViewImages
        batchStartingId={batchStartingId}
        setBatchStartingId={setBatchStartingId}
        useData={isUserAvailble}
        batchArr={batchArr}
        setBatchArr={setBatchArr}
      />
    </>
  );
}

export default App;
